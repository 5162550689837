<template>
  <!-- <div > -->
  <div class="framePage-scroll editLiveClass">
    <div class="ovy-a">
      <el-form
        class="el-form-liveClass"
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="130px"
      >
        <el-form-item
          label="班级名称："
          prop="liveProjectName"
          class="form-item"
        >
          <el-input
            v-model="Form.liveProjectName"
            placeholder="请输入班级名称"
            :disabled="disabled"
            maxlength="50"
            show-word-limit
            size="small"
          />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader
            v-model="Form.areaId"
            clearable
            filterable
            :options="areatreeList"
            :props="propsarea"
            :disabled="disabled"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker
            size="small"
            v-model="Form.Effectivedate"
             :disabled="disabled"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="所属期次："
          prop="periodId"
          class="form-item form-period"
        >
          <el-select
            v-model="Form.periodId"
            placeholder="请选择所属期次"
            size="small"
             :disabled="disabled"
          >
            <el-option
              v-for="item in periodList"
              :key="item.periodId"
              :label="item.periodName"
              :value="item.periodId"
            >
            </el-option>
          </el-select>
          <span style="margin-left: 10px">没有期次?</span>
          <el-button
            size="small"
            type="text"
            style="margin-left: 10px; font-size: 0.85rem"
            @click="addperiod()"
             :disabled="disabled"
            >点击新建期次</el-button
          >
        </el-form-item>
        <el-form-item label="负责人：" prop="projectUser" class="form-item">
          <el-input
            v-model="Form.projectUser"
            placeholder="请输入负责人"
            :disabled="disabled"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="负责人电话："
          prop="projectUserPhone"
          class="form-item"
        >
          <el-input
            v-model="Form.projectUserPhone"
            placeholder="请输入负责人电话"
            maxlength="12"
            :disabled="disabled"
            size="small"
          />
        </el-form-item>
        <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
           <el-input
            v-if="projectState == '20'|| projectState == '30'|| projectState == '50' || projectState == '60'"
            v-model="Form.Trainingtype"
            type="text"
            size="small"
            :disabled="true"
          />
          <tree
          v-else
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="Form"
            size="small"
            @clearParams="clearParams"
            modal
            type
            typeStu
          />
        </el-form-item>
        <el-form-item
          label="培训人数"
          prop="projectPeople"
          class="form-item"
          required
        >
          <el-input-number
            v-model="Form.projectPeople"
            @change="handleChange"
            :min="1"
            :max="999999999"
            style="width: 100%"
            :disabled="disabled"
            size="small"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv" :disabled="disabled">保存</el-button>
      </div>
    </div>
    <!-- 期次弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="3%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form
          label-width="80px"
          ref="FormPeriod"
          :model="FormPeriod"
          :rules="rulesPeriod"
        >
          <el-form-item label="期次名称" prop="periodName">
            <el-input
              v-model="FormPeriod.periodName"
              type="text"
              size="small"
              placeholder="请输入期次名称"
            />
          </el-form-item>
          <el-form-item label="培训人数" prop="periodtraineesNum">
            <el-input-number
              v-model="FormPeriod.periodtraineesNum"
              @change="handleChange"
              :min="1"
              style="width: 100%"
              size="small"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="doClose('FormPeriod')"
          >取消</el-button
        >
        <el-button class="bgc-bv" @click="getSure('FormPeriod')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "TestPaper",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };

    return {
      disabled: false,
      paperId: false,
      editJson: {},
      areatreeList: [],
      datatreeList: [], //培训类型
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      selectType: "0",
      Form: {},
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      rules: {
        liveprojectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          {
            required: true,
            message: "请选择培训类型",
            trigger: ["change", "blur"],
          },
        ],
      },
      periodList: [],
      /* 期次弹窗 */
      dialogVisible: false,
      dialogTitle: "新建期次",
      FormPeriod: {
        periodName: "",
        periodtraineesNum: "",
      },
      rulesPeriod: {
        periodName: [
          { required: true, message: "请输入期次名称", trigger: "blur" },
        ],
        periodtraineesNum: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],
      },
      // 培训状态
      projectState: ""
    };
  },
  created() {
    this.editJson = this.$route.query;
    this.liveProjectId = sessionStorage.getItem("liveProjectId");
    this.projectState = sessionStorage.getItem("projectState");
    this.getCrowdtypeList();
    this.getareatree();
    if (
      this.projectState == "30" ||
      this.projectState == "20" ||
      this.projectState == "50" || this.projectState == "60"
    ) {
      this.disabled = true;
    }
    this.getSelectPeriod();
    this.loadData(this.liveProjectId);
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    getSelectPeriod() {
      this.$post("/biz/live/period/getPeriodList", {}, 3000, true, 3)
        .then((ret) => {
          this.periodList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const list = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      this.CrowdtypeList = list;
    },
    loadData(liveProjectId) {
      this.$post(
        "/liveProject/live-project/getLiveProject",
        { liveProjectId },
        3000,
        true,
        3
      )
        .then((ret) => {
          let data = ret.data;
          this.Form = {
            ...data,
          };
          this.Form.Effectivedate = [
              data.startDate.replaceAll("/", "-"),
              data.endDate.replaceAll("/", "-"),
          ]
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (data.trainTypeNamePath) {
            name1 = data.trainTypeNamePath;
            this.params.trainFormId = data.trainTypeId;
            this.Form.trainFormId = data.trainTypeId;
          }
          if (data.postName) {
            name2 = "/" + data.postName;
            this.params.postFormId = data.postId;
          }
          if (data.industryNamePath) {
            name3 = "/" + data.industryNamePath;
            this.params.industryFormId = data.industryId;
          }
          if (data.occupationNamePath) {
            name4 = "/" + data.occupationNamePath;
            this.params.occFormId = data.occupationId;
          }
          if (data.trainLevelName) {
            name5 = "/" + data.trainLevelName;
            this.params.levelFormId = data.trainLevelId;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
        })
        .catch((err) => {
          return;
        });
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let parmar = {
        liveProjectName: this.Form.liveProjectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.deadline,
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        periodId: this.Form.periodId,
        liveProjectId: this.Form.liveProjectId,
      };
      if (this.Form.liveProjectId) {
        parmar.liveProjectId = this.Form.liveProjectId;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      this.$post("/liveProject/live-project/modify", parmar, 3000, true, 3)
        .then((ret) => {
          console.log(ret)
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              name: "liveClassManagement",
              params: {
                refresh: true,
                isAdd: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    /* 新建期次 */
    addperiod() {
      this.dialogVisible = true;
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs["FormPeriod"].resetFields();
      this.FormPeriod = {
        periodName: "",
        periodtraineesNum: "",
      };
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        periodName: this.FormPeriod.periodName,
        periodtraineesNum: this.FormPeriod.periodtraineesNum,
      };
      if (this.agencyStudentId) {
        parmar.agencyStudentId = this.agencyStudentId;
      }
      this.$post(
        this.stu == "add"
          ? "/agency/ma-agency-student/insert"
          : "/agency/ma-agency-student/modify",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scope>
.editLiveClass {
  .el-form-liveClass {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
    .form-period {
      .el-form-item__content {
        display: flex;
        align-items: center;
        .el-select {
          flex: 1;
        }
      }
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
