<template>
  <div>
    <div class="studentlist">
      <div
        class="
          operationControl
          flexdc
          operationControlCurr
          operationControlWidth
        "
        style="align-items: flex-start"
      >
        <div class="searchbox" style="padding-left: 0.5rem">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel">姓名:</span>
            <el-input
              v-model="userName"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="身份证号" class="searchboxItem ci-full">
            <span class="itemLabel">身份证号:</span>
            <el-input
              v-model="idcard"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel">手机号:</span>
            <el-input
              v-model="mobile"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="学习状态" class="searchboxItem ci-full">
            <span class="itemLabel">学习状态:</span>
            <el-select
              v-model="completeState"
              placeholder="请选择"
              clearable
              size="small"
            >
              <el-option
                v-for="item in complete"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="mini"
            class="bgc-bv"
            round
            @click="handleAdd('add')"
            v-if="
              projectSource != '30' ||
              (projectSource == '30' &&
                (auditState == '10' ||
                  auditState == '40' ||
                  auditState == '45'))
            "
            >新增学员</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="Exportstudent"
            v-if="
              projectSource != '30' ||
              (projectSource == '30' &&
                (auditState == '10' ||
                  auditState == '40' ||
                  auditState == '45'))
            "
            >导入学员</el-button
          >
        </div>
      </div>
    </div>

    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            width="80"
            fixed
          />
          <el-table-column
            label="姓名"
            align="left"
            show-overflow-tooltip
            prop="userName"
            min-width="120"
          />
          <el-table-column
            label="身份证号"
            align="left"
            show-overflow-tooltip
            prop="idcard"
            min-width="200"
          />
          <el-table-column
            label="手机号"
            align="left"
            show-overflow-tooltip
            prop="mobile"
            min-width="150"
          />
          <el-table-column
            label="入班时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="学习进度"
            align="right"
            show-overflow-tooltip
            prop="createTime"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.learnProgress }}
            </template>
          </el-table-column>
          <el-table-column label="学习状态" align="left" min-Width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.studyState != '40'">
                {{ $setDictionary("LEARNINGSTATE", scope.row.completeState) }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                :content="scope.row.notCompleteReasons"
                placement="top"
              >
                <span
                  style="
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                  >{{
                    $setDictionary("LEARNINGSTATE", scope.row.studyState)
                  }}</span
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="220"
          >
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="
                  lookstudent(
                    scope.row.liveProjectUserId,
                    scope.row.authentication,
                    'edit'
                  )
                "
                >查看</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="
                  scope.row.studyState == '30' ||
                  (projectSource == '30' &&
                    auditState != '10' &&
                    auditState != '40' &&
                    auditState != '45')
                "
                @click="handleDel(scope.row.liveProjectUserId)"
                >移除</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Certificationrecords(scope.row)"
                >认证记录</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="1%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="学员姓名" prop="studentname">
            <el-input
              v-model="Form.studentname"
              type="text"
              size="small"
              placeholder="请输入学员姓名"
              :disabled="disabled"
            />
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input
              v-model="Form.idCard"
              type="text"
              size="small"
              placeholder="请输入身份证号"
              :disabled="disabledcard"
              @input="
                (val) => {
                  getIdcard(val);
                }
              "
            />
            <div @click="showInfo" v-show="showtip" class="tip">
              {{ idcardmation || "" }}
            </div>
          </el-form-item>
          <el-form-item label="性别" prop="sex" required>
            <el-radio-group v-model="Form.sex" disabled>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile" class="Classitem">
            <el-input
              v-model="Form.mobile"
              type="text"
              size="small"
              :disabled="disabledcard"
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="cancleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="liveProjectName + '学员导入'"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              v-for="(item, index) in templatelist"
              :key="index"
              @click="downloadExcel(item.templatePath)"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              >{{ item.templateName }}</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档,并且选择学员学习的课程</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                @click="doExport"
                >开始导入</el-button
              >
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressVal"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      date: [],
      //
      stu: "add",
      dialogTitle: "新增学员信息",
      disabled: false,
      disabledcard: false,
      showtip: false,
      editshow: false,
      dialogVisible: false,
      centerDialogVisible: false,
      idcard: "",
      idcardmation: "",
      idcardObj: {},
      userJson: {},
      userName: "",
      mobile: "",
      completeState: "",
      complete: [],
      authenticationList: [],
      authenticationState: "",
      educationList: [],
      companyList: [],
      Form: {
        studentname: "",
        idCard: "",
        sex: "1",
        mobile: "",
      },
      rules: {
        studentname: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
      },
      activeName: "first",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      excelUrl: "",
      dialogDownload: false,
      dialogCert: false,
      docsDialogVisible: false,
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      fileName: "",
      progressVal:0,
      liveProjectName: sessionStorage.getItem("liveProjectName"),
      templatelist: "", // 导入模板
    };
  },
  created() {
    const query = this.$route.query;
    if (query.active == "three") {
      this.openOnce();
    }
  },
  methods: {
    // 每天弹一次  别删
    openOnce() {
      //每天首次进入的时候弹提示--------- 开始
      let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
      let user = JSON.parse(localStorage.getItem(userId)) || {};
      //获取当前时间
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowDate = year + "-" + month + "-" + day;

      if (!localStorage.getItem(userId)) {
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = {};
            userKey["StudentList"] = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      } else if (user.StudentList == undefined || user.StudentList < nowDate) {
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = JSON.parse(localStorage.getItem(userId));
            userKey.StudentList = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      }
      //每天首次进入的时候弹提示-------- 结束
    },
    init() {
      this.liveProjectId = sessionStorage.getItem("liveProjectId");
      this.getTableHeight();
      this.getcompleteList();
    },
    // 获取学习状态
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.complete = list;
    },
    //列表数据
    getData(pageNum) {
      // sessionStorage.setItem("refresh", 10);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveProjectId: this.liveProjectId,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.authenticationState) {
        params.authentication = this.authenticationState;
      }
      if (this.date) {
        params.startDate = this.date[0];
        params.endDate = this.date[1];
      }
      this.doFetch(
        {
          url: "/liveProject/live-project/studentPageList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    // 新增学员
    handleAdd(stu) {
      this.stu = stu;
      this.dialogTitle = "新增学员信息";
      this.dialogVisible = true;
      this.disabled = false;
      this.disabledcard = false;
      this.editshow = false;
    },
    reset() {
      this.Form = {
        studentname: "",
        idCard: "",
        sex: "1",
        mobile: "",
      };
    },
    getSure(formName) {
      if (this.stu == "add") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.doeAjax();
          }
        });
      } else {
        this.dialogVisible = false;
      }
    },
    // 列表 - 查看：点击确定
    doeAjax() {
      const parmar = {
        userName: this.Form.studentname,
        idcard: this.Form.idCard,
        sex: this.Form.sex,
        mobile: this.Form.mobile,
        liveProjectId: this.liveProjectId,
      };
      this.$post(
        this.stu == "add"
          ? "/liveProject/live-project/addProjectUser"
          : "biz/projectUser/updateCourseUser",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible = false;
            // this.doClose();
            this.getData();
            this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 从本班移除
    handleDel(liveProjectUserId) {
      this.$confirm("你确定要将该学员从本班移除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$post(
            "/liveProject/live-project/delProjectUser",
            { liveProjectUserId },
            3000,
            true,
            3
          )
            .then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.getData();
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {});
    },
    Learningrecords(row) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassStudentListLearningRecords",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    //认证记录
    Certificationrecords(row) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassStudentListCertificationrecords",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.blurcard(newValue);
        this.$post("/biz/user/queryUserByIdCard", {
          idcard: this.Form.idCard,
        })
          .then((res) => {
            if (!res.data) {
              this.showtip = false;
            } else {
              this.showtip = true;
              this.idcardObj = res.data;
              this.idcardmation =
                res.data.userName +
                  "/" +
                  res.data.idcard +
                  "/" +
                  res.data.mobile || "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.showtip = false;
      }
    },
    showInfo() {
      this.showtip = false;
      this.Form.studentname = this.idcardObj.userName;
      this.Form.idCard = this.idcardObj.idcard;
      // this.Form.sex = this.idcardObj.sex;
      this.Form.mobile = this.idcardObj.mobile;
    },
    //取消
    cancleForm(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.idcard = "";
      this.idcardObj = {};
      this.idcardmation = "";
      this.showtip = false;
      this.Form = {
        studentname: "",
        idcard: "",
        sex: "1",
        mobile: "",
      };
      this.getData();
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.idcard = "";
      this.idcardObj = {};
      this.idcardmation = "";
      this.showtip = false;
      this.Form = {
        studentname: "",
        idcard: "",
        sex: "1",
        mobile: "",
      };
      this.getData();
    },
    // 列表 - 查看 - 回显
    lookstudent(liveProjectUserId, authentication, stu) {
      if (authentication == "20") {
        this.disabled = true;
        this.editshow = false;
        this.disabledcard = true;
      } else {
        this.editshow = true;
        this.disabled = false;
        this.disabledcard = true;
      }
      this.authentication = authentication;
      this.Form.newCoursestudy = [];
      this.$forceUpdate();
      this.liveProjectUserId = liveProjectUserId;
      this.stu = stu;
      this.dialogTitle = "查看学员信息";
      this.dialogVisible = true;
      this.$post(
        "/liveProject/live-project/getLearnStudyInfo",
        { liveProjectUserId },
        3000,
        true,
        3
      )
        .then((ret) => {
          let records = ret.data;
          this.Form.studentname = records.userName;
          this.Form.idCard = records.idcard;
          this.Form.sex = records.sex;
          this.Form.mobile = records.mobile;
        })
        .catch((err) => {
          return;
        });
    },
    blurcard(e) {
      let sex = "";
      if (parseInt(e.substr(16, 1)) % 2 == 1) {
        //男
        sex = "1";
      } else {
        //女
        sex = "2";
      }
      this.Form.sex = sex;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= +35 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    /* 导入 */
    Exportstudent() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      this.centerDialogVisible = true;
      // 获取模板
      this.$post("/biz/project/user/v2/template", {
        projectId: this.liveProjectId,
      })
        .then((res) => {
          this.templatelist = res.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    downloadExcel(templatePath) {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = templatePath;
      let templateNames = templatePath.slice(8);
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },

    // 开始导入
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员绑定此班级?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                liveProjectId: this.liveProjectId,
                excelUrl: this.fileKey,
              };
              this.$post("/liveProject/live-project/import", parmar, 3000, true,3)
                .then((res) => {
                  if (res.status == '0') {
                    this.doProgress(res.data, this.liveProjectId);
                  }
                })
                .catch((err) => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //获取导入进度
    doProgress(batchId, liveProjectId) {
      console.log(batchId)
      this.$post(
        "/liveProject/live-project/import/progress",
        { batchId, liveProjectId },
        5000,
        true,
        3
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, liveProjectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/liveProject/live-project/exportProjectUser",
          { batchId: this.batchId, liveProjectId: this.liveProjectId },
          3000,
          true,
          3
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.getData();
    },
  },
};
</script>
<style lang="less">
// 截图
.cropper-content {
  .cropper {
    width: auto;
    height: 578px;
  }
}
.cropper_tip {
  background-color: #f1f7fd;
  padding: 15px 20px;
  h3 {
    margin-bottom: 15px;
  }
  .tip_index {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #5b6be7;
    color: #fff;
    border-radius: 50%;
    margin-right: 10px;
  }
  .tip_text {
    min-width: 140px;
  }
}
//
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.pic_upload {
  .el-upload {
    border: 0;
    display: block;
    max-height: 50px !important;
    text-align: left;
  }
}
.idPhoto {
  div {
    position: relative;
    display: flex;
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
// 图片裁剪
.vueCropper_dialog {
  .el-upload {
    display: inline-block;
    border: 0;
    height: 16px !important;
  }
}
</style>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>